import '@/styles/main.scss'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { svgSpritePlugin } from 'vue-svg-sprite'
import clickOutside from '@/directives/click-outside'
import src from '@/directives/src'
import mitt from 'mitt'
const emitter = mitt()
// import VueAware from 'vue-aware'

// Shortcodes
import Button from '@/components/g/Btn.vue'
import Picture from '@/components/g/Picture.vue'

const app = createApp(App)
app.config.globalProperties.emitter = emitter

app
  .use(store)
  .use(router)
  .use(svgSpritePlugin, { url: '/sprite.svg' })
  .directive('click-outside', clickOutside)
  .directive('src', src)
  // .use(VueAware)
  .component('g-btn', Button)
  .component('g-picture', Picture)
  .mount('#app')
