import api from '@/guides/api'

export const list = (type?: string) => {
  return api.get('/notifications', {
    params: {
      type,
    },
  })
}

export const read = (id: number) => {
  return api.put(`/notifications/${id}`)
}

export const remove = (id: number) => {
  return api.delete(`/notifications/${id}`)
}
