<template>
  <component
    :is="tag"
    :class="`btn${modifier ? `--${modifier}` : ''} btn--${content.color}`"
    :href="content.url"
    :target="content.target"
    v-if="content"
  >
    <span class="btn__label">
      {{ content.label }}
    </span>

    <SvgSprite
      v-if="modifier === 'download'"
      class="btn__icon"
      symbol="ui-download"
      size="16"
    />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'g-btn',
  props: {
    content: Object,
    tag: {
      type: String,
      default: 'a',
    },
    modifier: String,
  },
})
</script>

<style lang="scss" scoped>
[class*='btn--'],
.btn {
  @include fira-bold;
  display: inline-flex;
  align-items: center;
  margin-top: $spacing * 1.5;
  background: $regal-blue;
  color: $c-white;
  border: 0;
  text-align: center;
  padding: 1.45rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.btn--blue {
  background: $regal-blue;
}

.btn--green {
  background: $guide-green;
}

.btn__icon {
  margin-left: $spacing / 2;
}
</style>
