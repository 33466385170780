
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { list } from '@/guides/endpoints/news'
import { profile } from '@/guides/endpoints/profile'
import Badge from '@/components/Badge.vue'
import Card from '@/components/Card.vue'

export default defineComponent({
  name: 'Sidebar',
  components: {
    Badge,
    Card,
  },
  setup() {
    const badgesData = ref()
    const newsData = ref()

    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    list(5).then(r => {
      newsData.value = r.data.content
    })

    const updateProfile = () => {
      console.log('Refresh Sidebar')
      profile().then(r => {
        // Hide Oeuf d'or
        // badgesData.value = r.data.badges.slice(0, 4)

        const data = r.data.badges
        const icons = ['badge-5']

        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filteredArray = Object.values(data).filter(function(itm: any) {
            return icons.indexOf(itm.icon) === -1
          })

          badgesData.value = filteredArray.slice(0, 4)
        }
      })
    }

    updateProfile()

    emitter.on('updatetodo', updateProfile)

    return {
      badgesData,
      newsData,
    }
  },
})
