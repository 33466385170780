
import TimeChecker from '@/components/TimeChecker.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  components: {
    TimeChecker,
  },
  props: {
    data: Object,
    pageType: String,
  } as any, // eslint-disable-line
})
