
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BackBtn',
  props: {
    isQuali: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    const back = () => {
      router.back()
    }

    return {
      back,
    }
  },
})
