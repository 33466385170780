
import { defineComponent, ref, watch } from 'vue'
import RewardModal from '@/components/RewardModal.vue'

export default defineComponent({
  name: 'reward-launcher',
  components: {
    RewardModal,
  },
  props: {
    data: Object,
    modifier: String,
  } as any, // eslint-disable-line

  setup(props) {
    const modalVisible = ref(false)

    const fetch = () => {
      console.log('change', props.data.percent)
      if (props.data.percent === 0 || props.data.percent === 100) {
        modalVisible.value = true
      }
    }

    watch(() => props.data.percent, fetch)

    return {
      modalVisible,
    }
  },
})
