import { reactive } from 'vue'

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const PUBLIC_VAPID_KEY =
  'BDMSME8nfqQkGPuFdfO9r3bwGHd7wQ0ZI61EOFP9jupodYA50BaybzUTjX0RTKKrpyWlbwwUgLIFWNZmKau5Xec'

class GuidePushSubscribe {
  public canPush: boolean =
    'serviceWorker' in navigator && 'PushManager' in window
  private permission: NotificationPermission = 'default'
  private swRegistration: ServiceWorkerRegistration | null = null

  status = reactive({})

  setRegistration(r: ServiceWorkerRegistration) {
    this.permission = window.Notification.permission
    this.swRegistration = r
  }

  async getSubscription() {
    if (!this.swRegistration) {
      return false
    }

    return await this.swRegistration.pushManager.getSubscription()
  }

  async isSubscribed() {
    if (!this.swRegistration) {
      return false
    }

    const subscription = await this.getSubscription()
    return !(subscription === null)
  }

  async unsubscribe() {
    const subscription = await this.getSubscription()

    if (subscription) {
      await subscription.unsubscribe()
    }
  }

  async subscribe() {
    if (!this.swRegistration) {
      return false
    }

    if (this.permission !== 'granted') {
      this.permission = await window.Notification.requestPermission()
    }

    if (this.permission !== 'granted') {
      return false
    }

    const subscribe = await this.swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
    })

    return subscribe
  }
}

export default new GuidePushSubscribe()
