<template>
  <div class="picture-outer h-spacer-m">
    <div class="picture-inner">
      <img class="picture" v-src="content" :alt="content.alt" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'g-picture',
  props: {
    content: Object,
  },
})
</script>
<style lang="scss" scoped>
.picture-inner {
  @include aspect-ratio(16, 9);

  position: relative;
  width: 100%;
}

.picture {
  @include image-fit;
}
</style>
