import { push } from '@/inc/tracking'
import api from '@/guides/api'
import router from '@/router/index'

const LOCALSTORAGE_KEY = 'GUIDE_JWT'

const setAuth = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const isLogged = () => {
  return !!localStorage.getItem(LOCALSTORAGE_KEY)
}

export const setToken = (token: string) => {
  localStorage.setItem(LOCALSTORAGE_KEY, token)
  setAuth(token)
}

export const updateToken = () => {
  return api
    .put('/jwt')
    .then(r => {
      const { jwt } = r.data

      if (jwt) {
        setToken(jwt)
        return true
      }

      return false
    })
    .catch(() => {
      return false
    })
}

export const removeToken = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
  delete api.defaults.headers.common['Authorization']
}

export const logout = () => {
  removeToken()
  window.location.href = '/'
}

export const login = (username: string, password: string) => {
  return api
    .post('/login', {
      username,
      password,
    })
    .then(r => {
      const { firstConnection, jwt } = r.data

      // On login, track first connection
      if (firstConnection) {
        push({
          name: 'new-login',
          category: 'new-login',
          action: 'login',
          label: 'first-connection',
        })
      }

      if (jwt) {
        setToken(jwt)
        return true
      }

      return false
    })
    .catch(() => {
      return false
    })
}

if (isLogged()) {
  setAuth(localStorage.getItem(LOCALSTORAGE_KEY) as string)
}

api.interceptors.response.use(
  r => r,
  error => {
    if (error?.response?.status === 403) {
      console.log('remove token')
      removeToken()

      if (router?.currentRoute?.value?.name !== 'Login') {
        window.location.href = '/'
      }
    }

    return Promise.reject(error)
  }
)
