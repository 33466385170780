<template>
  <transition name="fade">
    <div :class="`alert ${type}`" v-if="show">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'error',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  left: 50%;
  top: 0px;
  width: 100%;
  max-width: 37.5rem;
  background: $monza;
  z-index: 100;
  padding: 15px;
  font-weight: 600;
  color: $c-white;
  font-size: 1rem;
  transform: translate(-50%, 0%);
  display: flex;
  align-items: center;

  @include mq(m) {
    font-size: 1.4rem;
  }
}

::v-deep(svg) {
  margin-right: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translate(-50%, -100%);
}
</style>
