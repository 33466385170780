<template>
  <header :class="{ stuck }">
    <div class="wrapper inner">
      <router-link :to="{ name: 'Home' }" class="logo">
        <img src="@/assets/images/logo-color.svg" alt="Logo Les Guides" />
      </router-link>

      <a
        href="https://guides.prod.apizsolutions.com/apiz/login"
        target="_blank"
        class="scribe"
      >
        <SvgSprite class="scribe__icon" symbol="icons-scribe" size="20 20" />
        <span class="scribe__label">SCRIBe</span>
        <SvgSprite class="scribe__svg" symbol="ui-external" size="16 16" />
      </a>

      <router-link
        v-if="profileData"
        :to="{ name: 'Profile' }"
        class="avatar-outer"
      >
        <div
          v-if="profileData.avatar"
          class="avatar"
          :style="
            `background-color: hsla(${profileData.avatar.color}, 100%, 50%, 0.6)`
          "
        >
          <img
            v-if="profileData.avatar.url"
            :src="profileData.avatar.url"
            :alt="profileData.firstname"
          />

          <SvgSprite
            v-else
            class="avatar--default"
            symbol="ui-default"
            size="16"
          />
        </div>
      </router-link>

      <BackBtn class="header__back" v-if="$route.meta.return" />

      <div class="actions">
        <NotificationBadge />

        <button
          class="toggler"
          :class="{ active: showNavigation }"
          @click.prevent="toggle"
        >
          <span></span>
          <span></span>
          <span></span>

          <SvgSprite class="icon" symbol="ui-close" size="20" />
        </button>
      </div>
      <!-- actions -->
    </div>
  </header>

  <nav class="navigation h4" v-show="showNavigation">
    <ul>
      <li>
        <router-link :to="{ name: 'Home' }">
          <SvgSprite symbol="icons-dashboard" size="26" />
          Accueil
        </router-link>
      </li>

      <li v-if="role != 'anime'">
        <router-link :to="{ name: 'Todo' }">
          <SvgSprite symbol="icons-todo" size="26" />
          To do
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'Wiki' }">
          <SvgSprite symbol="icons-share" size="26" />
          Espace de partage
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'Library' }">
          <SvgSprite symbol="icons-camp" size="26" />
          Mon animation
        </router-link>
      </li>

      <li>
        <router-link :to="{ name: 'News' }">
          <SvgSprite symbol="icons-news" size="26" />
          Actualités
        </router-link>
      </li>

      <!-- <li>
        <router-link :to="{ name: 'Events' }">
          <SvgSprite symbol="icons-agenda" size="26" />
          Évènements <span class="label-new">New</span>
        </router-link>
      </li> -->

      <!-- TEMP track#546
      <li>
        <router-link :to="{ name: 'Projects' }">
          <SvgSprite symbol="icons-projects" size="26" />
          Projets <span class="label-new">New</span>
        </router-link>
      </li> -->

      <li>
        <router-link :to="{ name: 'Contact' }">
          <SvgSprite symbol="icons-contact" size="26" />
          Contact
        </router-link>
      </li>

      <li>
        <a href="#" @click.prevent="logout">
          <SvgSprite symbol="icons-logout" size="26" />
          Déconnexion
        </a>
      </li>
    </ul>
  </nav>

  <div class="outside" v-show="showNavigation" @click.prevent="toggle"></div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
  getCurrentInstance,
} from 'vue'
import { logout } from '@/guides/endpoints/auth'
import { profile } from '@/guides/endpoints/profile'
import { useRoute } from 'vue-router'
import NotificationBadge from '@/components/NotificationBadge.vue'
import BackBtn from '@/components/BackBtn.vue'

export default defineComponent({
  name: 'Header',
  components: {
    BackBtn,
    NotificationBadge,
  },
  setup() {
    const profileData = ref()
    const route = useRoute()
    const showNavigation = ref(false)
    const y = ref(window.scrollY)
    const role = ref()

    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const stuck = computed(() => {
      return y.value > 400
    })

    const onScroll = () => {
      y.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    const updateProfile = () => {
      profile().then(r => {
        profileData.value = r.data
        role.value = r.data?.role
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      })
    }

    updateProfile()

    emitter.on('updateavatar', updateProfile)

    watch(
      () => route.name,
      () => {
        showNavigation.value = false
      }
    )

    const toggle = () => {
      showNavigation.value = !showNavigation.value
    }

    return {
      logout,
      profileData,
      showNavigation,
      stuck,
      toggle,
      role,
    }
  },
})
</script>

<style scoped lang="scss">
header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.5rem;
  z-index: 10;
  background-color: $c-white;
  transition: background-color 0.3s;

  &.stuck {
    @include mq($until: m) {
      background-color: $c-white;
    }
  }
}

.inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  max-width: initial;
}

.logo {
  display: none;
}

.avatar-outer {
  position: relative;
  margin-right: 2rem;
  width: 4rem;
  height: 4rem;
  flex: 0 0 4rem;
  border-radius: 99em;
  background: $c-white;
  transition: opacity 0.3s;

  @include mq($until: s) {
    margin-right: auto;
    order: -1;
  }

  @include mq(m) {
    &:hover {
      opacity: 0.7;
    }
  }
}

.avatar {
  @include get-all-space;

  padding: 0.5rem;
  border-radius: 99em;
  background-color: $rock-blue;
}

.avatar--default {
  @include center-xy;

  width: 100%;
  height: 56.25%;
}

.actions {
  display: flex;
  align-items: center;

  .notifications {
    @include expand(10px);
    font-size: 0;
    margin-right: 20px;

    @include mq(s) {
      margin-right: 30px;
    }

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}

.toggler {
  @include expand(10px);
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 15px;
  border: 0;
  background: transparent;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &::before {
    z-index: 2;
  }

  .icon {
    display: none;
  }

  span {
    display: block;
    width: 20px;
    height: 3px;
    background: $regal-blue;

    transition: transform 0.5s ease;
  }

  &.active {
    .icon {
      @include center-xy;

      display: block;
      width: 3rem;
      height: 3rem;

      ::v-deep(svg) {
        fill: $regal-blue;
      }
    }
    span {
      transform: scale(0);
    }
  }
}

@include mq(s) {
  header {
    height: 8.6rem;
  }

  .logo {
    display: block;
    flex: 1;

    img {
      width: 9.6rem;
    }
  }
}

@include mq(m) {
  header {
    border-bottom: 1px solid $botticelli;
  }
}

// Navigation

.navigation {
  background: $athens-gray;
  position: fixed;
  z-index: 13;
  left: 0;
  width: 100%;
  filter: drop-shadow(0px 64px 64px rgba(0, 0, 0, 0.25));

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }

  a.router-link-active {
    // color: $c-white;
    background: $c-white;
  }

  a:not(.router-link-active):hover {
    // color: $c-white;
    background: rgba($regal-blue, 0.2);
  }

  svg {
    margin-right: 10px;
  }

  @include mq(s) {
    right: 0;
    left: auto;
    width: 280px;

    &::before {
      position: absolute;
      right: 15px;
      transform: translate(-50%, -50%) rotate(45deg);
      content: '';
      width: 16px;
      height: 16px;
      z-index: -1;
      background: $athens-gray;
    }
  }
}

.outside {
  @include get-all-space;
  position: fixed;
  z-index: 12;
  background: transparent;
  cursor: pointer;
}

.scribe {
  @include fira;

  margin-right: 1.6rem;
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;

  font-size: 18px;
  line-height: 1;
  color: white;
  background-color: $scribe-blue;

  @include mq(m) {
    padding: 1.2rem;
  }

  &:hover {
    opacity: 0.8;
  }

  &__label {
    @include mq($until: s) {
      display: none;
    }
  }

  &__svg {
    margin-left: 1.2rem;
  }
}

.label-new {
  @include label-new;

  margin-left: auto;
}
</style>
