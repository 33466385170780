<template>
  <transition name="fade">
    <div class="reward-modal" v-if="visible">
      <div class="layer" @click.prevent="close"></div>

      <div class="reward-modal__content">
        <div class="reward-modal__badge">
          <SvgSprite
            class="icon"
            :symbol="`badges-${data.icon}-${data.level}`"
            size="90"
          />
        </div>
        <div class="h4">
          Badge <strong>{{ data.title }}</strong> obtenu !
        </div>
        <div class="h1">Bravo !</div>
        <div class="reward-modal__message" v-html="data.message"></div>
        <Btn @click.prevent="close">En avant</Btn>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Btn from '@/components/Btn.vue'

export default defineComponent({
  name: 'RewardModal',
  components: {
    Btn,
  },
  props: {
    data: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
    }

    return {
      close,
    }
  },
})
</script>

<style lang="scss" scoped>
.reward-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $athens-gray;
  z-index: 20;

  @include mq(s) {
    background: transparent;
  }

  @include mq(m) {
    top: 8.6rem;
    width: calc(100% - 40rem);
  }

  @include mq(xl) {
    width: calc(100% - 48rem);
  }
}

.layer {
  @include get-all-space;
  background: rgba($botticelli, 0.9);
  cursor: pointer;

  @include mq($until: s) {
    display: none;
  }
}

.reward-modal__content {
  @include center-xy;
  width: 100%;
  padding: $spacing * 1.5;
  text-align: center;
  background: transparent;

  .h1 {
    margin-top: $spacing;
    margin-bottom: 0;
    line-height: 1.1;
  }

  .h4 {
    margin-top: $spacing * 2;
    color: $rock-blue;

    strong {
      color: $regal-blue;
    }
  }

  ::v-deep(button) {
    max-width: 30rem;
  }

  @include mq(s) {
    padding: $spacing * 2.5;
    width: 50%;
    background: $c-white;
  }

  @include mq(m) {
    padding: $spacing * 1.5;
    width: 55%;
  }

  @include mq(xl) {
    padding: $spacing * 3;
    width: 60%;
  }
}

.reward-modal__message {
  ::v-deep(p) {
    @include liant-bold;
    font-size: 3.2rem;
    line-height: 1.5;
    color: $regal-blue;

    @include mq(m) {
      font-size: 4.8rem;
    }
  }
}

.reward-modal__badge {
  position: relative;
  margin: auto;
  width: 21rem;
  height: 18rem;

  &:before {
    @include get-all-space;
    content: '';
    background: url('~@/assets/images/congratulations.svg');
    background-size: cover;
    animation: sk-bouncedelay 1.5s infinite ease-in-out;
  }

  .icon {
    @include get-all-space;
    padding: 1rem 1rem 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes sk-bouncedelay {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
</style>
