import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isLogged } from '@/guides/endpoints/auth'

const ONLY_GUEST = 'onlyGuest'
const ONLY_LOGGED = 'onlyLogged'
// const EVERYBODY = 'everybody'

const routes: Array<RouteRecordRaw> = [
  {
    // TODO: delete
    path: '/dev-tracking',
    name: 'Tracking',
    meta: { type: ONLY_GUEST },
    component: () =>
      import(/* webpackChunkName: "tracking" */ '../views/Tracking.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { type: ONLY_GUEST },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/',
    name: 'Home',
    meta: { type: ONLY_LOGGED },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/news/',
    name: 'News',
    meta: { type: ONLY_LOGGED },
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: '/news/:slug',
    name: 'NewsDetails',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/NewsDetails.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { type: ONLY_LOGGED },
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/contact/carrick',
    name: 'ContactForm',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "contactform" */ '../views/forms/ContactForm.vue'
      ),
  },
  {
    path: '/todo',
    name: 'Todo',
    meta: { type: ONLY_LOGGED },
    component: () => import(/* webpackChunkName: "todo" */ '../views/Todo.vue'),
  },
  {
    path: '/wiki/:category?/:list?',
    name: 'Wiki',
    meta: { type: ONLY_LOGGED, return: true },
    component: () => import(/* webpackChunkName: "wiki" */ '../views/Wiki.vue'),
    props: true,
  },
  {
    path: '/wiki/search/:term/:category?/:list?',
    name: 'WikiSearch',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "wikisearch" */ '../views/WikiSearch.vue'),
    props: true,
  },
  {
    path: '/wiki/searchby/:adjectives/:category?/:list?',
    name: 'WikiSearchByAdjectives',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "wikisearchbyadjectives" */ '../views/WikiSearchByAdjectives.vue'
      ),
    props: true,
  },

  // FORM WIKI
  {
    path: '/wiki/add-game/:subcat?',
    name: 'WikiAddGame',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "gameform" */ '../views/forms/GameForm.vue'),
  },
  {
    path: '/wiki/add-quali',
    name: 'WikiAddQuali',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "qualiform" */ '../views/forms/QualiForm.vue'
      ),
  },
  {
    path: '/wiki/add-recipe/:subcat?',
    name: 'WikiAddRecipe',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "recipeform" */ '../views/forms/RecipeForm.vue'
      ),
  },
  {
    path: '/wiki/add-tip/:subcat?',
    name: 'WikiAddTip',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "tipform" */ '../views/forms/TipForm.vue'),
  },
  {
    path: '/wiki/add-totem/:subcat?',
    name: 'WikiAddTotem',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "totemform" */ '../views/forms/TotemForm.vue'
      ),
  },
  {
    path: '/wiki/add-woodcraft/:subcat?',
    name: 'WikiAddWoodcraft',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "woodcraftform" */ '../views/forms/WoodcraftForm.vue'
      ),
  },
  {
    path: '/wiki/add-ritual',
    name: 'WikiAddRitual',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "ritualform" */ '../views/forms/RitualForm.vue'
      ),
  },
  {
    path: '/wiki/add-ads',
    name: 'WikiAddAds',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "ritualform" */ '../views/forms/AdsForm.vue'),
  },

  // WIKI DETAILS
  {
    path: '/activities/:slug',
    name: 'Activity',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
  },
  {
    path: '/recipes/:slug',
    name: 'Recipe',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "recipe" */ '../views/Recipe.vue'),
  },
  {
    path: '/tips/:slug',
    name: 'Tip',
    meta: { type: ONLY_LOGGED, return: true },
    component: () => import(/* webpackChunkName: "tip" */ '../views/Tip.vue'),
  },
  {
    path: '/qualis/:slug',
    name: 'Quali',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "quali" */ '../views/Quali.vue'),
  },
  {
    path: '/totems/:slug',
    name: 'Totem',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "totem" */ '../views/Totem.vue'),
  },
  {
    path: '/woodcrafts/:slug',
    name: 'Woodcraft',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "woodcraft" */ '../views/Woodcraft.vue'),
  },
  {
    path: '/rituals/:slug',
    name: 'Ritual',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "ritual" */ '../views/Ritual.vue'),
  },
  {
    path: '/ads/:slug',
    name: 'Ads',
    meta: { type: ONLY_LOGGED, return: true },
    component: () => import(/* webpackChunkName: "ads" */ '../views/Ads.vue'),
  },

  {
    path: '/wiki-card/:slug',
    name: 'WikiDetail',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "wiki-detail" */ '../views/WikiDetail.vue'),
  },
  {
    path: '/library/:category?/:list?',
    name: 'Library',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "library" */ '../views/Library.vue'),
    props: true,
  },
  {
    path: '/library/search/:term/:category?/:list?',
    name: 'LibrarySearch',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "librarysearch" */ '../views/LibrarySearch.vue'
      ),
    props: true,
  },
  {
    path: '/library-card/:slug',
    name: 'LibraryDetail',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "library-detail" */ '../views/LibraryDetail.vue'
      ),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: { type: ONLY_LOGGED },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/Notifications.vue'
      ),
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: { type: ONLY_LOGGED },
    component: () =>
      import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
  },
  {
    path: '/projects/:slug',
    name: 'Project',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "project" */ '../views/Project.vue'),
  },
  {
    path: '/projects/add-project',
    name: 'AddProject',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "projectform" */ '../views/forms/ProjectForm.vue'
      ),
  },
  {
    path: '/events',
    name: 'Events',
    meta: { type: ONLY_LOGGED },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/events/:slug',
    name: 'Event',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(/* webpackChunkName: "event" */ '../views/EventDetails.vue'),
  },
  {
    path: '/order-publication',
    name: 'OrderPublication',
    meta: { type: ONLY_LOGGED, return: true },
    component: () =>
      import(
        /* webpackChunkName: "orderform" */ '../views/forms/OrderForm.vue'
      ),
  },
  {
    path: '/:slug',
    name: 'Default',
    meta: { type: ONLY_LOGGED },
    component: () =>
      import(/* webpackChunkName: "default" */ '../views/Default.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const dstType = to.meta.type || ONLY_LOGGED

  if (!isLogged() && dstType === ONLY_LOGGED) {
    next({ name: 'Login' })
  } else if (isLogged() && dstType === ONLY_GUEST) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
