
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
  getCurrentInstance,
} from 'vue'
import { logout } from '@/guides/endpoints/auth'
import { profile } from '@/guides/endpoints/profile'
import { useRoute } from 'vue-router'
import NotificationBadge from '@/components/NotificationBadge.vue'
import BackBtn from '@/components/BackBtn.vue'

export default defineComponent({
  name: 'Header',
  components: {
    BackBtn,
    NotificationBadge,
  },
  setup() {
    const profileData = ref()
    const route = useRoute()
    const showNavigation = ref(false)
    const y = ref(window.scrollY)
    const role = ref()

    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const stuck = computed(() => {
      return y.value > 400
    })

    const onScroll = () => {
      y.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    const updateProfile = () => {
      profile().then(r => {
        profileData.value = r.data
        role.value = r.data?.role
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      })
    }

    updateProfile()

    emitter.on('updateavatar', updateProfile)

    watch(
      () => route.name,
      () => {
        showNavigation.value = false
      }
    )

    const toggle = () => {
      showNavigation.value = !showNavigation.value
    }

    return {
      logout,
      profileData,
      showNavigation,
      stuck,
      toggle,
      role,
    }
  },
})
