<template>
  <div :class="`badge${modifier ? `--${modifier}` : ''}`">
    <div class="badge-inner">
      <!-- Temp -->
      <SvgSprite
        v-if="data.level"
        class="badge__icon"
        :symbol="`badges-${data.icon}-${data.level}`"
        size="90"
      />
      <SvgSprite
        v-else
        class="badge__icon"
        :symbol="`badges-${data.icon}`"
        size="90"
      />
      <div class="badge__content">
        <h4 class="badge__title">{{ data.title }}</h4>
        <div class="badge__text" v-html="data.text"></div>
        <div class="badge__progress-outer">
          <div class="badge__progress">
            <span
              class="badge__progress-fill"
              :style="`width: ${data.percent}%`"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="badge__percentage">{{ data.percent }}%</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Badge',
  props: {
    data: Object,
    modifier: String,
  } as any, // eslint-disable-line
})
</script>

<style lang="scss" scoped>
[class*='badge--'],
.badge {
  position: relative;
  border: 1px solid $athens-gray;
  padding: $spacing * 0.25 $spacing $spacing * 0.25 $spacing * 0.25;
  background-color: $c-white;

  &[class*='--small'] {
    padding: 0;
    border: 0;
  }
}

.badge-inner {
  display: flex;

  .badge--small & {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}

.badge__icon {
  position: relative;
  flex-shrink: 0;
  margin-right: $spacing * 1.25;
  width: 9rem;
  height: auto;

  .badge--small & {
    margin-right: 0;
    width: 100%;
  }
}

.badge__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: $spacing / 2;
}

.badge__title {
  padding-top: $spacing / 2;

  .badge--small & {
    padding-top: $spacing / 4;
    font-size: 1.2rem;
    text-align: center;
  }
}

.badge__text {
  @include owl;

  margin-top: $spacing / 4;
  font-size: 1.2rem;
  line-height: 1.4em;

  .badge--small & {
    display: none;
  }
}

.badge__percentage {
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
  display: flex;
  align-items: center;
  color: $hippie-blue;
  font-size: 10px;

  .badge--small & {
    display: none;
  }
}

.badge__progress-outer {
  margin-top: auto;
}
.badge__progress {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 1px;
  background-color: $botticelli;

  .badge--small & {
    margin: 1rem auto;
    width: 6rem;
  }
}

.badge__progress-fill {
  display: block;
  height: 3px;
  background-color: $tangerine;
}
</style>
