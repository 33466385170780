<template>
  <router-link
    v-if="isQuali"
    class="back-btn"
    :to="{
      name: 'Wiki',
      params: {
        category: 'qualis',
      },
    }"
  >
    <SvgSprite class="back-btn__icon" symbol="ui-arrow-right" size="10" />
    <span>Retour</span>
  </router-link>

  <a v-else href="#" class="back-btn" @click.prevent="back">
    <SvgSprite class="back-btn__icon" symbol="ui-arrow-right" size="10" />
    <span>Retour</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BackBtn',
  props: {
    isQuali: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    const back = () => {
      router.back()
    }

    return {
      back,
    }
  },
})
</script>

<style scoped lang="scss">
.back-btn {
  position: fixed;
  top: 0;
  left: $spacing;
  margin-bottom: $spacing;
  display: none;
  align-items: center;
  height: 5.5rem;
  font-size: 1.4rem;
  color: $tangerine;
  background-color: $c-white;
  z-index: 10;

  transition: opacity 0.3s;

  svg {
    stroke: $tangerine;
    stroke-width: 2;
  }

  .activity &,
  .ads-details &,
  .library-details &,
  .news-details &,
  .event-details &,
  .project &,
  .quali &,
  .recipe &,
  .totem &,
  .woodcraft & {
    .branch-horizon &,
    .branch-route & {
      color: white;

      svg {
        stroke: white;
      }
    }
  }

  @include mq(m) {
    &:hover {
      opacity: 0.8;

      .back-btn__icon {
        transform: rotate(180deg) translateX(3px);
      }
    }
  }

  header & {
    display: flex;
  }

  @include mq(s) {
    display: flex;
    position: absolute;
    top: $spacing;
    left: $spacing * 1.5;
    height: auto;
    background: transparent;
    z-index: 5;

    header & {
      display: none;
    }
  }
}

.back-btn__icon {
  margin-right: 1rem;
  transform: rotate(180deg);
  transition: transform 0.3s;
}
</style>
