
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { list } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'NotificationBadge',
  setup() {
    const REFRESH = 5 // seconds
    const newNotifications = ref(false)
    let tick: number

    const check = () => {
      if (!navigator.onLine) {
        return
      }

      list().then(r => {
        newNotifications.value = r?.data?.unread
      })
    }

    check()

    onMounted(() => {
      tick = setInterval(check, REFRESH * 1000)
    })

    onUnmounted(() => {
      tick && clearInterval(tick)
    })

    return {
      newNotifications,
    }
  },
})
