<template>
  <div>
    <RewardLauncher
      :data="badge"
      v-for="badge in badgesData"
      :key="`badge-${badge.label}`"
      modifier="small"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from 'vue'

import { profile } from '@/guides/endpoints/profile'
import RewardLauncher from '@/components/RewardLauncher.vue'

export default defineComponent({
  name: 'Hide-Seek',
  components: {
    RewardLauncher,
  },

  setup() {
    const badgesData = ref()
    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    const updateProfile = () => {
      console.log('Refresh Badge')
      profile().then(r => {
        badgesData.value = r.data.badges
      })
    }

    updateProfile()

    emitter.on('updatetodo', updateProfile)

    return {
      badgesData,
    }
  },
})
</script>
