<template>
  <div class="card" :class="`card${pageType ? `--${pageType}` : ''}`">
    <div class="card__picture-outer">
      <img
        class="card__picture"
        v-if="data.picture"
        v-src="data.picture"
        :alt="data.picture.alt"
      />
    </div>
    <div class="card-inner">
      <div class="card__category" v-if="pageType === 'event'">
        <SvgSprite
          class="header__category__icon"
          :symbol="
            `icons-${data.tags
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')}`
          "
          size="20"
        />
        <span class="h5">{{ data.tags }}</span>
        <span class="label-new">New</span>
      </div>

      <h4 class="card__title" v-html="data.title"></h4>
      <div
        v-if="pageType != 'ads'"
        class="card__text"
        v-html="data.text || data.lead"
      ></div>

      <div
        class="card__date"
        v-if="data.date && pageType != 'ads' && pageType != 'event'"
      >
        <SvgSprite class="card__date__icon" symbol="ui-calendar" size="10" />
        <TimeChecker
          class="card__date__label"
          :data="{ date: data.date }"
          type="news-card"
        />
      </div>

      <!-- Date if Event -->
      <div class="card__date--event" v-if="data.date && pageType === 'event'">
        <SvgSprite
          class="card__date--event__icon"
          symbol="icons-calendar"
          size="18"
        />
        <div class="h3">{{ data.date }}</div>
      </div>

      <!-- Date if Ads -->
      <div class="card__date--event" v-if="data.date && pageType === 'ads'">
        <SvgSprite
          class="card__date--event__icon"
          symbol="icons-calendar"
          size="18"
        />
        <TimeChecker
          class="card__date__label"
          :data="{ date: data.date }"
          type="news-card"
        />
      </div>

      <!-- Category if Ads -->
      <div
        class="card__ads__category"
        v-if="data.category && pageType === 'ads'"
      >
        <div>{{ data.category }}</div>
      </div>
    </div>
    <SvgSprite class="card__arrow" symbol="ui-arrow-right" size="16" />

    <!-- LINK -->
    <a
      v-if="pageType && pageType === 'event' && data.type === 'external'"
      :href="data.url"
      target="_blank"
      class="card__link"
    ></a>
    <router-link
      v-else-if="pageType && pageType === 'event'"
      :to="data.url"
      class="card__link"
    ></router-link>
    <router-link
      v-else
      :to="data.link || data.url"
      class="card__link"
    ></router-link>
  </div>
</template>

<script lang="ts">
import TimeChecker from '@/components/TimeChecker.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  components: {
    TimeChecker,
  },
  props: {
    data: Object,
    pageType: String,
  } as any, // eslint-disable-line
})
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: $c-white;
  border: 1px solid $athens-gray;
  transition: transform 0.3s;
}

.card:hover {
  .card__picture {
    transform: scale(1.05);
  }

  .card__arrow {
    transform: translateX(3px);
  }

  @include mq($until: m) {
    &:hover,
    &:active {
      transform: scale(1.05);
    }
  }
}

.card--ads:hover {
  .card__picture {
    @include center-xy;
  }
}

.card__picture-outer {
  @include aspect-ratio(10, 11);

  position: relative;
  flex-shrink: 0;
  width: 10rem;
  background-color: $botticelli;
  overflow: hidden;

  .card--ads & {
    background-color: $c-white;
  }
}

.card__picture {
  @include get-all-space;
  object-fit: cover;
  transition: transform 0.3s;
  will-change: transform;

  .card--ads & {
    @include center-xy;

    width: 5.6rem;
    height: 5.6rem;
    border-radius: 5.6rem;
    background: $branch-horizon;
  }
}

.card__title {
  .card--ads & {
    padding-right: 7.8rem;
    line-height: 1.2;
  }
}

.card__category {
  @include fira-bold;

  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  svg {
    margin-right: 0.4rem;
  }
}

.card-inner {
  padding: $spacing * 0.75 1.5rem $spacing;

  @include mq(m) {
    padding: $spacing * 0.75 $spacing $spacing;
  }
}

.card__text {
  @include owl;

  margin-top: $spacing / 4;
  font-size: 1.2rem;
  line-height: 1.4em;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card__date {
  display: flex;
  align-items: center;
  margin-top: $spacing * 0.75;
}

.card__date--event {
  position: absolute;
  top: 1.5rem;
  right: 2rem;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }

  .h3 {
    @include fira;

    color: $hippie-blue;
    font-size: 1.2rem;
  }
}

.card__date__icon {
  width: 1rem;
  height: 1rem;
  margin-right: $spacing / 4;
}

.card__date__label {
  display: flex;
  align-items: center;
  margin-right: $spacing;
  color: $hippie-blue;
  font-size: 1rem;
  line-height: 1.2em;

  .card--ads & {
    margin-right: 0;
  }

  @include mq(m) {
    font-size: 1.2rem;
  }
}

.card__arrow {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  pointer-events: none;
  stroke: $tangerine;
  stroke-width: 2;
  transition: transform 0.3s;
  will-change: transform;
}

.card__link {
  @include get-all-space;
}

.label-new {
  @include label-new;

  margin-left: 0.4rem;

  @include mq($until: m) {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
  }
}

.card__ads__category {
  @include label-new;

  position: absolute;
  left: 10rem;
  bottom: 1rem;
  margin-left: 1.5rem;
  background-color: $rock-blue;

  @include mq(m) {
    margin-left: $spacing;
  }
}
</style>
