<template>
  <button class="h3" :disabled="disabled">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Btn',
  props: {
    disabled: Boolean,
  },
})
</script>

<style scoped lang="scss">
button {
  @include fira-bold;
  width: 100%;
  background: $regal-blue;
  color: $c-white;
  border: 0;
  text-align: center;
  padding: 1.45rem;
  cursor: pointer;
  text-transform: uppercase;

  @include mq(m) {
    font-size: 1.4rem;
  }
}

button:hover {
  opacity: 0.8;
}

button:disabled,
button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
