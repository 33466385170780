
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { isLogged, updateToken } from '@/guides/endpoints/auth'
import { profile } from '@/guides/endpoints/profile'
import api from '@/guides/api'

import HideSeek from '@/components/HideSeek.vue'
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Alert from '@/components/Alert.vue'
import { DetectUA } from 'detect-ua'
// import Refresh from '@/components/Refresh.vue'

import { init } from '@/inc/tracking'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    HideSeek,
    Sidebar,
    // Refresh,
    Alert,
  },

  provide() {
    return {
      device: new DetectUA(),
    }
  },

  setup() {
    // Init tracking
    init()
    const isOffline = ref<boolean>(!navigator.onLine)
    const profileData = ref()
    const showNotAvailable = ref(false)
    const router = useRouter()
    const route = useRoute()

    const showChrome = computed(() => {
      if (route.name === 'Login') {
        return false
      }

      if (!isLogged()) {
        return false
      }

      return true
    })

    const branch = computed(() => {
      if (showChrome.value === true) {
        profile().then(r => {
          // console.log('profile', r)
          profileData.value = r.data.branch
        })

        const teseract = `branch-${profileData.value}`

        return teseract.toLowerCase()
      }

      return ''
    })

    const updateSession = () => {
      if (isLogged() && navigator.onLine) {
        updateToken()
      }
    }

    const MINUTES_REFRESH = 5
    window.setInterval(updateSession, MINUTES_REFRESH * 60 * 1000)

    onMounted(() => {
      window.addEventListener('offline', () => {
        isOffline.value = true
      })

      window.addEventListener('online', () => {
        isOffline.value = false
      })
    })

    api.interceptors.response.use(
      r => r,
      error => {
        if (!error.status && isOffline.value) {
          showNotAvailable.value = true

          window.setTimeout(() => {
            showNotAvailable.value = false
          }, 5000)
        }
      }
    )

    return {
      router,
      route,
      branch,
      showChrome,
      isOffline,
      profileData,
      showNotAvailable,
    }
  },
})
