import api from '@/guides/api'

export const me = () => {
  return api.get('/me')
}

export const profile = () => {
  return api.get('/profile')
}

export const avatars = () => {
  return api.get('/avatars')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const save = (data: any) => {
  return api.post('/avatar', data)
}
