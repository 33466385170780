<template>
  <div class="offline" v-if="isOffline">
    Hey ! pour l'instant, tu es hors ligne. Tu peux toujours naviguer dans
    l'appli, mais certaines fonctionnalités sont limitées.
  </div>

  <Alert :show="showNotAvailable">
    <SvgSprite symbol="icons-alert" size="0 0 14 11" />
    <span>Cette action n'est pas réalisable hors connexion.</span>
  </Alert>

  <Header v-if="showChrome" />

  <div class="app" :class="branch">
    <div class="view">
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component" :key="route.path" />
        </transition>
      </router-view>

      <HideSeek v-if="showChrome" />
    </div>

    <Sidebar v-if="showChrome" />
  </div>

  <!-- <Refresh /> -->
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { isLogged, updateToken } from '@/guides/endpoints/auth'
import { profile } from '@/guides/endpoints/profile'
import api from '@/guides/api'

import HideSeek from '@/components/HideSeek.vue'
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Alert from '@/components/Alert.vue'
import { DetectUA } from 'detect-ua'
// import Refresh from '@/components/Refresh.vue'

import { init } from '@/inc/tracking'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    HideSeek,
    Sidebar,
    // Refresh,
    Alert,
  },

  provide() {
    return {
      device: new DetectUA(),
    }
  },

  setup() {
    // Init tracking
    init()
    const isOffline = ref<boolean>(!navigator.onLine)
    const profileData = ref()
    const showNotAvailable = ref(false)
    const router = useRouter()
    const route = useRoute()

    const showChrome = computed(() => {
      if (route.name === 'Login') {
        return false
      }

      if (!isLogged()) {
        return false
      }

      return true
    })

    const branch = computed(() => {
      if (showChrome.value === true) {
        profile().then(r => {
          // console.log('profile', r)
          profileData.value = r.data.branch
        })

        const teseract = `branch-${profileData.value}`

        return teseract.toLowerCase()
      }

      return ''
    })

    const updateSession = () => {
      if (isLogged() && navigator.onLine) {
        updateToken()
      }
    }

    const MINUTES_REFRESH = 5
    window.setInterval(updateSession, MINUTES_REFRESH * 60 * 1000)

    onMounted(() => {
      window.addEventListener('offline', () => {
        isOffline.value = true
      })

      window.addEventListener('online', () => {
        isOffline.value = false
      })
    })

    api.interceptors.response.use(
      r => r,
      error => {
        if (!error.status && isOffline.value) {
          showNotAvailable.value = true

          window.setTimeout(() => {
            showNotAvailable.value = false
          }, 5000)
        }
      }
    )

    return {
      router,
      route,
      branch,
      showChrome,
      isOffline,
      profileData,
      showNotAvailable,
    }
  },
})
</script>

<style lang="scss">
.sidebar {
  display: none;
}

.view {
  position: relative;
  padding-bottom: 100px;
}

.view > *:last-child {
  padding-bottom: 5rem;

  @include mq(m) {
    padding-bottom: 10rem;
  }
}

.offline {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $c-dev-warning;
  color: $c-white;
  padding: 10px 20px;
  font-size: 18px;
  z-index: 100;
  text-align: center;
}

@include mq(m) {
  .app {
    display: flex;
  }

  .view {
    position: relative;
    flex: 1;
  }

  .sidebar {
    display: block;
    width: 40rem;
  }
}

@include mq(l) {
  .app.wrapper {
    padding: 0 10rem;
  }
}

@include mq(xl) {
  .sidebar {
    width: 48rem;
  }
}

.temp {
  padding: $spacing !important;
  text-align: center;
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
