import { VNode } from 'vue'

// Directives
export interface VueSrcData {
  src: string
  rawUrl: string
}
export type VueSrcSets = string[]
export type VueSrcTransform = string | string[]
interface VueSrcAttrs {
  src: string
  srcset?: string
}

const defaultSets: string[] = [
  '160',
  '240',
  '320',
  '480',
  '640',
  '960',
  '1280',
  '1440',
  '1600',
  '1920',
  '2240',
  '2560',
  '2880',
]

const createSignedAttrs = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs: Record<string, any>
): VueSrcAttrs => {
  const { sets: pictureSets, src } = values
  let sets = attrs && attrs.sets ? attrs.sets : defaultSets
  sets = Array.isArray(sets) ? sets : sets.split(',')

  const srcset = sets
    .filter((size: string) => pictureSets[size])
    .map((size: string) => `${pictureSets[size]} ${size}w`)
    .join(', ')

  return { src, srcset }
}

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeMount(el: HTMLElement, binding: any, vnode: VNode) {
    if (el.hasAttribute('src')) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { props } = vnode as { props: Record<string, any> }

    const { src, srcset } = createSignedAttrs(binding.value, props)

    el.setAttribute('src', src)

    if (srcset) {
      el.setAttribute('srcset', srcset)
    }
  },
}
