<template>
  <div class="time-checker">{{ displayedDate }}</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import moment from 'moment'
moment.updateLocale('fr', {
  relativeTime: {
    future: 'Dans %s',
    past: 'Il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
})

export default defineComponent({
  name: 'TimeChecker',
  props: {
    data: Object,
    type: {
      type: String,
      default: () => 'task',
    },
  } as any, // eslint-disable-line
  setup(props) {
    const getDate = (date: number) => {
      const day = moment(date * 1000).startOf('day')

      return day.format('DD/MM/YYYY')
    }

    const getTaskDate = (date: string) => {
      const day = moment(date).startOf('day')
      const today = moment().startOf('day')
      const duration = moment.duration(day.diff(today))
      const daysAgo = duration.days()

      if (daysAgo === 0) {
        return 'Aujourd\'hui'
      }

      if (daysAgo < -7) {
        return day.format('DD/MM/YYYY')
      }

      return duration.humanize(true)
    }

    const getNotificationDate = (date: string) => {
      const day = moment(date)
      const today = moment()
      const duration = moment.duration(day.diff(today))
      const daysAgo = duration.days()

      if (daysAgo < -7) {
        return day.format('DD/MM/YYYY')
      }

      return duration.humanize(true)
    }

    const displayedDate = computed(() => {
      const { date } = props.data

      switch (props.type) {
        case 'task':
          return getTaskDate(date)
        case 'notification':
          return getNotificationDate(date)
        default:
          return getDate(date)
      }
    })

    return {
      displayedDate,
    }
  },
})
</script>

<style lang="scss" scoped>
.time-checker {
  // color: $regal-blue;
  // font-size: 1rem;
  // line-height: 1em;
  // font-weight: 600;
  white-space: nowrap;
}
</style>
