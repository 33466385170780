<template>
  <aside class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-badges pb-l">
        <div class="sidebar-badges__header">
          <h3>Mes badges</h3>
          <router-link :to="{ name: 'Profile' }">
            <span>Voir tout</span>
            <SvgSprite
              class="icon-arrow-right"
              symbol="ui-arrow-right"
              size="16"
            />
          </router-link>
        </div>
        <div class="sidebar-badges__list">
          <Badge
            :data="badge"
            v-for="badge in badgesData"
            :key="`badge-${badge.label}`"
            modifier="small"
          />
        </div>
      </div>
      <div
        class="sidebar-news pb-l"
        v-if="newsData && newsData.news.items.length"
      >
        <h3>À la une</h3>
        <Card
          :data="news"
          v-for="news in newsData.news.items"
          :key="`news-${news.title}`"
        />
      </div>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { list } from '@/guides/endpoints/news'
import { profile } from '@/guides/endpoints/profile'
import Badge from '@/components/Badge.vue'
import Card from '@/components/Card.vue'

export default defineComponent({
  name: 'Sidebar',
  components: {
    Badge,
    Card,
  },
  setup() {
    const badgesData = ref()
    const newsData = ref()

    const internalInstance = getCurrentInstance()
    const emitter = internalInstance?.appContext.config.globalProperties.emitter

    list(5).then(r => {
      newsData.value = r.data.content
    })

    const updateProfile = () => {
      console.log('Refresh Sidebar')
      profile().then(r => {
        // Hide Oeuf d'or
        // badgesData.value = r.data.badges.slice(0, 4)

        const data = r.data.badges
        const icons = ['badge-5']

        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filteredArray = Object.values(data).filter(function(itm: any) {
            return icons.indexOf(itm.icon) === -1
          })

          badgesData.value = filteredArray.slice(0, 4)
        }
      })
    }

    updateProfile()

    emitter.on('updatetodo', updateProfile)

    return {
      badgesData,
      newsData,
    }
  },
})
</script>

<style scoped lang="scss">
.sidebar {
  z-index: 3;
  padding-bottom: $spacing * 2;
  background: $c-white;
  border-left: 1px solid $rock-blue;
  min-height: calc(100vh - 8.6rem);

  .icon-arrow-right {
    stroke: $tangerine;
    stroke-width: 2;
  }
}

.sidebar-inner {
  position: sticky;
  top: 15rem;

  > div {
    padding: 0 $spacing * 2.4;

    &:first-child {
      margin-top: 4rem;
      margin-bottom: $spacing * 2;
      padding-bottom: $spacing;
      border-bottom: 1px solid $botticelli;
    }

    @include mq(xl) {
      padding: 0 $spacing * 3;
    }
  }
}

.sidebar-badges__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-badges__header a {
  display: flex;
  align-items: center;
  color: $tangerine;
  font-size: 1.4rem;
  line-height: 1.4;

  &:hover {
    opacity: 0.8;

    svg {
      transform: translateX(3px);
    }
  }

  svg {
    transition: transform 0.3s;
  }

  span {
    margin-right: $spacing / 2;
  }
}

.sidebar-badges__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  margin-left: -$spacing;
  margin-right: -$spacing;
  padding: $spacing 0;
}

.card {
  margin-top: $spacing * 1.35;
  border: none;

  ::v-deep(h4) {
    @include mq(m) {
      font-size: 1.3rem;
    }
    @include mq(xl) {
      font-size: 1.4rem;
    }
  }

  ::v-deep(.card__text) {
    @include mq(m) {
      font-size: 1.1rem;
    }
    @include mq(xl) {
      font-size: 1.2rem;
    }
  }
}
</style>
