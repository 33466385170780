
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'g-btn',
  props: {
    content: Object,
    tag: {
      type: String,
      default: 'a',
    },
    modifier: String,
  },
})
