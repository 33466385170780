import api from '@/guides/api'

export const list = (limit = 10) => {
  return api.get(`/pages/actus?limit=${limit}`)
}

export const filteredList = (month: string | string[]) => {
  return api.get(`/pages/actus?month=${month}`)
}

export const single = (slug: string | string[]) => {
  return api.get(`/news/${slug}`)
}

export const order = (data: any) => {
  return api.post('/news/order', data)
}
