
import { defineComponent } from 'vue'
import Btn from '@/components/Btn.vue'

export default defineComponent({
  name: 'RewardModal',
  components: {
    Btn,
  },
  props: {
    data: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const close = () => {
      document.body.classList.remove('no-scroll')
      ctx.emit('update:visible', false)
    }

    return {
      close,
    }
  },
})
