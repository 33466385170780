class SwChannel {
  private swRegistration: ServiceWorkerRegistration | null = null

  setRegistration(r: ServiceWorkerRegistration) {
    this.swRegistration = r
    console.log('sw channel register')
  }

  send(key: string, data = {}) {
    if (!this.swRegistration) {
      return
    }

    this.swRegistration.active?.postMessage(
      JSON.stringify({
        key,
        data,
      })
    )
  }
}

export default new SwChannel()
