const { VUE_APP_RELEASE } = process.env
const shouldTrack =
  VUE_APP_RELEASE === 'staging' || VUE_APP_RELEASE === 'production'

// Add support for: dataLayer, gtag, beforeinstallprompt…
interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: DataLayer[]
    gtag(cmd: 'event', action: TrackingAction, params: GtagLayer): void
  }

  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }
}

type TrackingCategory = string // 'installation-click'
type TrackingAction = string // 'download-app'
type TrackingValue = number

interface TrackingLayer {
  name: string
  category: TrackingCategory
  action: TrackingAction
  label: string
  value?: TrackingValue
}

interface GtagLayer {
  event_category: TrackingCategory
  event_label: string
  value?: TrackingValue
}

interface DataLayer {
  event: string
  category: TrackingCategory
  action: TrackingAction
  label: string
  value?: TrackingValue
}

export const push = (layer: TrackingLayer) => {
  if (shouldTrack) {
    if (window.gtag) {
      const { category, action, label, value } = layer
      const gtagAction = action
      /* eslint-disable @typescript-eslint/camelcase */
      const gtagLayer: GtagLayer = {
        event_category: category,
        event_label: label,
      }

      if (value) {
        gtagLayer.value = value
      }
      /* eslint-enable @typescript-eslint/camelcase */

      window.gtag('event', gtagAction, gtagLayer)
    } else {
      const { name, category, action, label, value } = layer
      const dataLayer: DataLayer = {
        event: name,
        category,
        label,
        action,
      }

      if (value) {
        dataLayer.value = value
      }

      window.dataLayer.push(dataLayer)
    }
  } else {
    console.log(`\n📈 dataLayer.push [${event}]`)
    console.table(layer)
  }
}

export const init = () => {
  console.log('tracking:init')
  // https://web.dev/customize-install/#detect-install
  // Setup a listener to track Add to Homescreen events.
  // window.addEventListener(
  //   'beforeinstallprompt',
  //   (e: BeforeInstallPromptEvent) => {
  //     console.log('tracking:beforeinstallprompt', e, e.userChoice)
  //     e.userChoice.then(choiceResult => {
  //       console.log('tracking:choiceResult', choiceResult)
  //       push({
  //         name: 'installation-click',
  //         category: 'download-app',
  //         action: 'click',
  //         label: choiceResult.platform || 'unknown',
  //       })
  //     })
  //   }
  // )
  window.addEventListener('appinstalled', () => {
    console.log('tracking:appinstalled')
    push({
      name: 'app-install',
      category: 'app',
      action: 'install',
      label: 'success',
    })
  })

  //Track from where your web app has been opened/browsed
  // https://medium.com/@suncommander/how-to-show-pwa-install-banner-add-to-homescreen-for-your-website-b1fbe6ebfdb5
  // https://web.dev/customize-install/#track-how-the-pwa-was-launched
  // https://stackoverflow.com/questions/54580414/how-can-i-detect-if-my-website-is-opened-inside-a-trusted-web-actvity
  window.addEventListener('load', () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches
    let label: string

    if (document.referrer.startsWith('android-app://')) {
      label = 'twa'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((navigator as any).standalone || isStandalone) {
      label = 'standalone'
    } else {
      label = 'browser'
    }

    console.log('tracking:load', label)

    push({
      name: 'app-launch',
      category: 'app',
      action: 'launch',
      label,
    })
  })
}
