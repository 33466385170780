<template>
  <router-link class="notifications" :to="{ name: 'Notifications' }">
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41468 18.8783C9.20158 18.8597 9.95672 18.5564 10.5465 18.0221C11.1363 17.4877 11.5228 16.7567 11.6375 15.9584H5.06689C5.18491 16.7784 5.58945 17.5266 6.20527 18.0637C6.82109 18.6008 7.60624 18.8903 8.41468 18.8783Z"
        fill="#668CAF"
      />
      <path
        d="M15.8686 14.3411C15.3778 13.894 14.9482 13.3815 14.5912 12.8174C14.2014 12.0387 13.9678 11.1882 13.9041 10.316V7.74708C13.9075 6.37712 13.4211 5.05306 12.5364 4.02366C11.6517 2.99426 10.4296 2.33038 9.09961 2.15676V1.48593C9.09961 1.3018 9.02802 1.12522 8.9006 0.995025C8.77318 0.86483 8.60036 0.791687 8.42016 0.791687C8.23996 0.791687 8.06714 0.86483 7.93972 0.995025C7.8123 1.12522 7.74072 1.3018 7.74072 1.48593V2.16717C6.42267 2.3533 5.2153 3.02119 4.34223 4.04713C3.46915 5.07307 2.98954 6.38754 2.99223 7.74708V10.316C2.92848 11.1882 2.69489 12.0387 2.30515 12.8174C1.95442 13.3802 1.53164 13.8927 1.04804 14.3411C0.993754 14.3898 0.950244 14.4498 0.920407 14.517C0.890571 14.5843 0.875092 14.6572 0.875 14.7311V15.4383C0.875 15.5762 0.928621 15.7085 1.02407 15.806C1.11951 15.9036 1.24897 15.9584 1.38395 15.9584H15.5327C15.6677 15.9584 15.7972 15.9036 15.8926 15.806C15.988 15.7085 16.0417 15.5762 16.0417 15.4383V14.7311C16.0416 14.6572 16.0261 14.5843 15.9963 14.517C15.9664 14.4498 15.9229 14.3898 15.8686 14.3411Z"
        fill="#004079"
      />
      <ellipse
        v-if="newNotifications"
        cx="13.1569"
        cy="3.77085"
        rx="2.97917"
        ry="2.97917"
        fill="#f28c00"
      />
    </svg>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { list } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'NotificationBadge',
  setup() {
    const REFRESH = 5 // seconds
    const newNotifications = ref(false)
    let tick: number

    const check = () => {
      if (!navigator.onLine) {
        return
      }

      list().then(r => {
        newNotifications.value = r?.data?.unread
      })
    }

    check()

    onMounted(() => {
      tick = setInterval(check, REFRESH * 1000)
    })

    onUnmounted(() => {
      tick && clearInterval(tick)
    })

    return {
      newNotifications,
    }
  },
})
</script>

<style lang="scss" scoped></style>
